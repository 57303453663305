<template lang="pug">
include ../../../configs/template.pug
div.row.text-left
  div(v-if="!$route.params.documentID").col-12
    +select-validation('body.medical_institution', 'medicalInstitutionsList', 'medicalInstitution', '"value"', '["required"]')
  div.col-12
    +select-validation('body.position', 'medicalPositionsList', 'position', 'nameLang', '["required"]')
  div(v-if="checkAccess('medicalStatement', 'view_file')").col-12
    FileDropZone(ref="mediaContent")
  div.col-12.text-center
    v-btn(@click="saveMedicalStatement" :loading="isLoading" color="success") {{ $t('btn.save') }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { sailorMedicalStatement } from '@/mixins/validationRules'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'
const initBoody = () => ({
  medical_institution: null,
  position: null
})
export default {
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBoody(),
      files: [],
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      medicalInstitutionsList: state => state.directory.medInstitution.filter(el => !el.is_disabled),
      medicalPositionsList: state => state.directory.positionMedical
    })
  },
  mounted () {
    if (this.$route.params?.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
    }
  },
  validations () { return sailorMedicalStatement(this) },
  methods: {
    ...mapActions(['updateMedicalStatementById', 'getMedicalStatementByID', 'setMedicalStatement']),

    async saveMedicalStatement () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_medical_certificate'
        }
      }

      const response = await this[this.$route.params?.documentID ? 'updateMedicalStatementById' : 'setMedicalStatement'](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        if (!this.$route.params?.documentID) { // If called adding component
          this.$notification.success('notify.success.medicalStatementAdded')
          this.$parent.isViewAddSlot = false
          this.$v.$reset()
        } else this.$notification.success('editedMedDoc')
      }
      this.isLoading = false
    }
  }
}
</script>
